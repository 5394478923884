<template>
  <div class="mt-1">
    <b-card header="Indirizzo" header-tag="header" class="mt-1">
      <b-card-text>
        <b-row>
          <div class="form-group col-md-3">
            <base-select
              vid="home_country"
              :name="beECForm.attribute_NOR_value.label"
              :label="coass ? 'Nazione' : beECForm.attribute_NOR_value.label"
              v-model="inputVal[repository].attribute_NOR_value"
              :options="inputVal.optHomeCountries"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              @select="onSelectHomeCountry"
              :disabled="disabled"
            />
            <!-- @input="onInputHomeCountry" -->
          </div>
          <div class="form-group col-md-3" v-if="!isForeign">
            <base-select
              name="home_province"
              vid="home_province"
              label="Provincia"
              v-model="inputVal.home_province"
              :options="inputVal.optHomeProvinces"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              @select="onSelectHomeProvince"
              @remove="onRemoveHomeProvince"
              :rules="
                dynamicRules ? dynRules('home_province') : rules.home_province
              "
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3" v-if="!isForeign">
            <base-select
              vid="attribute_CITY_value"
              :name="beECForm.attribute_CITY_value.label"
              :label="beECForm.attribute_CITY_value.label"
              v-model="inputVal[repository].attribute_CITY_value"
              :options="inputVal.optHomeCouncils"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              :rules="
                dynamicRules
                  ? dynRules('attribute_CITY_value')
                  : rules.attribute_CITY_value
              "
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3" v-else>
            <base-input
              vid="attribute_CITY2_value"
              :name="beECForm.attribute_CITY2_value.label"
              :label="beECForm.attribute_CITY2_value.label"
              v-model="inputVal[repository].attribute_CITY2_value"
              placeholder="Inserisci il comune estero"
              :rules="
                dynamicRules
                  ? dynRules('attribute_CITY2_value')
                  : rules.attribute_CITY2_value
              "
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              vid="attribute_ZIPC_value"
              :name="beECForm.attribute_ZIPC_value.label"
              :label="beECForm.attribute_ZIPC_value.label"
              v-model="inputVal[repository].attribute_ZIPC_value"
              placeholder="Inserisci il CAP"
              maxlength="5"
              :rules="
                dynamicRules
                  ? dynRules('attribute_ZIPC_value')
                  : rules.attribute_ZIPC_value
              "
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-textarea
              vid="attribute_ADDR_value"
              :name="beECForm.attribute_ADDR_value.label"
              :label="beECForm.attribute_ADDR_value.label"
              v-model="inputVal[repository].attribute_ADDR_value"
              placeholder="Inserisci un indirizzo"
              :rules="
                dynamicRules
                  ? dynRules('attribute_ADDR_value')
                  : rules.attribute_ADDR_value
              "
              :disabled="disabled"
            />
          </div>
          <div class="form-group col-md-3">
            <base-input
              vid="attribute_NAME2_value"
              :name="beECForm.attribute_NAME2_value.label"
              :label="beECForm.attribute_NAME2_value.label"
              v-model="inputVal[repository].attribute_NAME2_value"
              placeholder="Inserisci il nominativo"
              :rules="
                dynamicRules
                  ? dynRules('attribute_NAME2_value')
                  : rules.attribute_NAME2_value
              "
              :disabled="disabled"
            />
          </div>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      defaultCountryName: "Italia",
    };
  },
  props: {
    beECForm: {
      type: Object,
      default() {
        return {};
      },
    },
    dynamicRules: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    coass: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rules: {
      type: Object,
      default() {
        return {
          attribute_NOR_value: {},
          attribute_CITY_value: {},
          attribute_CITY2_value: {},
          home_province: {},
          attribute_ZIPC_value: {},
          attribute_ADDR_value: {},
          attribute_NAME2_value: {},
        };
      },
    },
    repository: {
      type: String,
    },
    value: null,
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea: () => import("@/components/form/BaseTextarea"),
  },
  beforeMount() {
    if (!this.inputVal[this.repository].attribute_NOR_value) {
      let country_id;
      // imposta italia
      let found = this.countries().find(
        (c) => c.text == this.defaultCountryName
      );
      if (found) {
        country_id = found.value;
        this.inputVal[this.repository].attribute_NOR_value = country_id;
        // imposta le provinci
        this.inputVal.optHomeProvinces = this.provinces()(country_id);
      }
    }
  },
  methods: {
    onSelectHomeCountry(selected) {
      this.inputVal.home_province = null;
      this.inputVal.optHomeProvinces = this.provinces()(selected);
      this.inputVal.optHomeCouncils = [];

      let italy = this.countries().find(
        (c) => c.text == this.defaultCountryName
      );

      if (selected !== italy.value) {
        this.inputVal[this.repository].attribute_CITY_value =
          this.councils()().find((el) => el.code === "ZZ").value;
        this.inputVal[this.repository].attribute_ZIPC_value = null;
        this.inputVal[this.repository].attribute_ADDR_value = null;
      } else {
        this.inputVal[this.repository].attribute_CITY_value = null;
        this.inputVal[this.repository].attribute_CITY2_value = null;
        this.inputVal[this.repository].attribute_ZIPC_value = null;
        this.inputVal[this.repository].attribute_ADDR_value = null;
      }
    },
    onSelectHomeProvince(selected) {
      this.inputVal.optHomeCouncils = [];
      this.inputVal[this.repository].attribute_CITY_value = null;
      this.inputVal.optHomeCouncils = this.councils()(selected);
    },
    onRemoveHomeProvince() {
      this.inputVal[this.repository].attribute_CITY_value = null;
      this.inputVal.optHomeCouncils = [];
    },
    dynRules(field) {
      let rules = {};
      switch (field) {
        case "home_province":
          if (
            !this.isForeign &&
            (this.inputVal[this.repository].attribute_CITY_value ||
              this.inputVal[this.repository].attribute_ADDR_value ||
              this.inputVal[this.repository].attribute_ZIPC_value)
          ) {
            rules.required = true;
          }
          break;
        case "attribute_CITY_value":
          if (
            !this.isForeign &&
            (this.inputVal.home_province ||
              this.inputVal[this.repository].attribute_ADDR_value ||
              this.inputVal[this.repository].attribute_ZIPC_value)
          ) {
            rules.required = true;
          }
          break;
        case "attribute_CITY2_value":
          if (this.isForeign) {
            rules.required = true;
          }
          break;
        case "attribute_ZIPC_value":
          break;
        case "attribute_ADDR_value":
          if (
            (!this.isForeign &&
              (this.inputVal.home_province ||
                this.inputVal[this.repository].attribute_CITY_value ||
                this.inputVal[this.repository].attribute_ZIPC_value)) ||
            this.isForeign
          ) {
            rules.required = true;
          }
          break;
        default:
          break;
      }
      return rules;
    },
    ...mapGetters("auth", [
      "countries",
      "provinces",
      "provinceByProvinceId",
      "countries",
      "provinces",
      "councils",
    ]),
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isForeign() {
      if (!this.inputVal[this.repository]?.attribute_NOR_value) return false;
      let italy = this.countries().find(
        (c) => c.text == this.defaultCountryName
      );
      let ret =
        this.inputVal[this.repository].attribute_NOR_value !== italy.value;
      return ret;
    },
  },
};
</script>
